<template>
    <div>
        <h1>
            qAssess <em>Preview Edition</em>
        </h1>

        <b-card class="mt-0" header="Browser Compatibility"
            header-text-variant="white"
            header-tag="header"
            header-bg-variant="secondary">
            <b-card-text class="text-left">
                We officially support the last 2-3 versions of each major web browser.  Previous versions may work, 
                but are not officially supported.  If you have problems, upgrade your browser to the latest version and try again.
                <br><br>
                <b-container>
                    <b-row>
                        <b-col sm="12" md="6" class="text-left">
                            <strong>Computer (PC/Mac/Chromebook):</strong>
                            <ul>
                                <li>Chrome: 86&ndash;88</li>
                                <li>Firefox: 82&ndash;83</li>
                                <li>Microsoft Edge: 86&ndash;88</li>
                                <li>Safari: 13.1&ndash;14</li>
                            </ul>
                        </b-col>
                        <b-col sm="12" md="6" class="text-left">
                            <strong>Mobile:</strong>
                            <br>
                            Currently mobile devices (phones & tablets) are NOT supported.  We are working on tablet support.

                            <!--
                            <ul>
                                <li>Chrome for Android: 86</li>
                                <li>Safari for iOS: 14 & 13.4-13.7</li>
                            </ul>  
                            
                            Large screen tablets Only! Phones not recommended or supported.
                            -->
                        </b-col>                        
                    </b-row>
                </b-container>

                <br>
                Internet Explorer is NOT supported (all versions).
                <!--<br><br>
                The use of tablets is supported, but a computer with keyboard and mouse is strongly recommended.
                With a tablet, using a stylus ("pen") is recommended. Drawing accurately with your finger is difficult.            
                -->
            </b-card-text>
            <div class="mt-2">
                <b-link to="Login" class="center">Return to Login Page</b-link>
            </div>
        </b-card>  
    </div>
</template>

<script>
export default {
    name: 'Browser'
}
</script>

<style scoped>
    h1 {
        font-size: 2rem;
        margin: 70px auto 20px auto;
    }
    .card {
        margin: 10px auto 80px auto;
        min-width: 400px;
        max-width: 700px;
    }
    .card-header {
        background-color: black;
        color: white;
        font-weight: bold;
        font-size: 1.3rem;
    }
    .card-body {
        background-color: #f7f7f7;
        color: #000;
    }   
</style>